import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CartsCollection } from '@models/cart';
import { OrderModel } from '@models/order';

import { updateOrder as updateOrderAction } from '@actions/panel/cart_view/orders';

import http from '@/utils/http';

class RefundForm extends Component {
  constructor(props) {
    super(props);

    const orderCarts = props.carts.filterByOrderId(props.refundableOrder.id);

    this.state = {
      order: props.refundableOrder,
      refundItems: orderCarts.map((cart) => ({
        cartId: cart.id,
        productName: cart.product_name,
        totalValue: cart.final_price,
        refundValue: 0.0,
      })),
      deliveryRefundItem: {
        deliveryType: props.refundableOrder.delivery_type,
        totalValue: props.refundableOrder.final_delivery_price,
        refundValue: 0.0,
      },
      confirmed: false,
      totalRefund: 0.0,
    };
  }

  handleRefundConfirm = (e) => {
    this.setState({ confirmed: e.target.checked });
  }

  calculateTotalRefund = (e) => {
    const formData = Object.fromEntries(new FormData(e.target.form));
    let sum = 0;

    $('form.refund-form .refund-input').each((_index, input) => {
      sum += Number($(input).val());
    });

    this.setState((prevState) => {
      const refundItems = prevState.refundItems.map((item) => (
        { ...item, refundValue: formData[`carts[${item.cartId}][value]`] }
      ));

      const deliveryRefundItem = {
        ...prevState.deliveryRefundItem,
        refundValue: formData.shipping_refund_value,
      };

      return { refundItems, deliveryRefundItem, totalRefund: sum };
    });
  }

  getDeliveryLabel = (deliveryType) => {
    const option = this.props.deliveryOptions.find((d) => d.delivery_type === deliveryType);

    return option ? option.label : '---';
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { order } = this.state;
    const { onCancel, updateOrder } = this.props;

    http.post(OrderModel.routes.orderRefundsPath(order.id), new FormData(e.target))
      .then((response) => {
        updateOrder(response.data);

        window.flashMessages.addMessage({
          type: 'success',
          text: 'Refund has been generated.',
        });

        onCancel();
      })
      .catch((error) => {
        this.setState({ order: new OrderModel(error.response.data) });

        window.flashMessages.addMessage({
          type: 'error',
          text: 'Refund cannot be generated due to some errors.',
        });
      });
  }

  render() {
    const {
      order,
      confirmed,
      totalRefund,
      refundItems,
      deliveryRefundItem,
    } = this.state;

    const { onCancel } = this.props;

    const unsuccessfulTransactionError = order.errors.full_messages.find((err) => err.indexOf('E00027') > -1);

    return (
      <form onSubmit={this.handleFormSubmit} className="refund-form">
        {order.errors.full_messages.length > 0 && (
          <p className="alert alert-danger">
            {`${order.errors.full_messages.join(' ')} `}
          </p>
        )}

        {unsuccessfulTransactionError && (
          <p className="alert alert-warning">
            Please check also if order payment was already settled by Authorize.Net
          </p>
        )}

        <p className="mb-3">Are you sure you want to process a refund?</p>

        <table className="refund-table">
          <thead>
            <tr>
              <th><span className="px-2 py-1">Order</span></th>
              <th><span className="px-2 py-1">Product</span></th>
              <th className="text-right"><span className="px-2 py-1">Total</span></th>
              <th className="text-right"><span className="px-2 py-1">Remaining</span></th>
              <th className="text-right"><span className="px-2 py-1">Refund</span></th>
            </tr>
          </thead>
          <tbody>
            {refundItems.map((item) => (
              <tr className="refund-table-row" key={item.cartId}>
                <td className="pb-3">
                  <span className="input-group-text">{item.cartId}</span>
                </td>
                <td className="pb-3">
                  <span className="input-group-text">{item.productName}</span>
                </td>
                <td className="pb-3">
                  <span className="input-group-text light justify-content-end">
                    ${Number(item.totalValue).toFixed(2)}
                  </span>
                </td>
                <td className="pb-3">
                  <span className="input-group-text light justify-content-end">
                    ${Number(item.totalValue - item.refundValue).toFixed(2)}
                  </span>
                </td>
                <td className="pb-3">
                  <input
                    type="number"
                    name={`carts[${item.cartId}][value]`}
                    min="0"
                    max={item.totalValue}
                    step="0.01"
                    value={item.refundValue}
                    onChange={this.calculateTotalRefund}
                    className="form-control refund-input text-right"
                  />
                </td>
              </tr>
            ))}
            <tr className="refund-table-row">
              <td className="pb-3">
                <span className="input-group-text">Shipping</span>
              </td>
              <td className="pb-3">
                <span className="input-group-text">{this.getDeliveryLabel(deliveryRefundItem.deliveryType)}</span>
              </td>
              <td className="pb-3">
                <span className="input-group-text light justify-content-end">
                  ${Number(deliveryRefundItem.totalValue).toFixed(2)}
                </span>
              </td>
              <td className="pb-3">
                <span className="input-group-text light justify-content-end">
                  ${Number(deliveryRefundItem.totalValue - deliveryRefundItem.refundValue).toFixed(2)}
                </span>
              </td>
              <td className="pb-3">
                <input
                  type="number"
                  name="shipping_refund_value"
                  min="0"
                  max={deliveryRefundItem.totalValue}
                  step="0.01"
                  value={deliveryRefundItem.refundValue}
                  onChange={this.calculateTotalRefund}
                  className="form-control refund-input text-right"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="row font-weight-bold mb-2">
          <span>Total before refund</span>
          <div className="ml-auto">
            ${Number(order.total_price).toFixed(2)}
          </div>
        </div>
        <div className="row font-weight-bold">
          <span>Refund</span>
          <div className="ml-auto">
            - ${Number(totalRefund).toFixed(2)}
          </div>
        </div>
        <hr />
        <div className="row font-weight-bold">
          <span>Remaining:</span>
          <div className="ml-auto">
            ${Number(order.total_price - totalRefund).toFixed(2)}
          </div>
        </div>

        <div className="input-group mt-4 mb-3">
          <div className="field form-check pl-0">
            <input
              type="checkbox"
              id="payment_skipped"
              name="payment_skipped"
              checked={confirmed}
              onChange={this.handleRefundConfirm}
              className="custom-control-input"
            />
            <label htmlFor="payment_skipped" className="input-label checkbox-label">
              Yes, I am sure
            </label>
          </div>
        </div>

        <div className="col row justify-content-end px-0">
          <button
            type="reset"
            className="btn btn-sm btn-light mr-3"
            onClick={onCancel}
          >
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={!confirmed}
          >
            Refund
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  carts: new CartsCollection(state.carts.all_carts),
});

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (order) => dispatch(updateOrderAction(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundForm);
