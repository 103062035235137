import React, { Component } from 'react';

import { OrderModel } from '@models/order';

import FormDialog from '@components/shared/FormDialog';

import OrderItem from './OrderItem';
import RefundForm from './RefundForm';

class OrdersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refundDialogStatus: false,
      refundableOrder: new OrderModel(),
    };
  }

  handleOpenDialog = (order) => {
    this.setState({ refundDialogStatus: true, refundableOrder: order });
  }

  handleCloseDialog = () => {
    this.setState({ refundDialogStatus: false });
  }

  render() {
    const { orders, deliveryOptions } = this.props;
    const { refundDialogStatus, refundableOrder } = this.state;

    return (
      <div className="open-carts-list">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="text-center">Order #</th>
                <th scope="col">Cart #</th>
                <th scope="col">Drug</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
                <th scope="col">Discount</th>
                <th scope="col">Branch</th>
                <th scope="col">Created</th>
                <th scope="col">By</th>
                <th scope="col">Payment</th>
                <th scope="col">Delivery</th>
                <th scope="col">Refund</th>
                <th scope="col">Void</th>
              </tr>
            </thead>
            <tbody>
            {orders.map((order) => (
                <OrderItem
                  key={order.id}
                  order={order}
                  openRefundForm={this.handleOpenDialog}
                  deliveryOptions={deliveryOptions}
                />
              ))}
            </tbody>
          </table>
        </div>

        <FormDialog
          title="Refund"
          isOpen={refundDialogStatus}
          onClose={this.handleCloseDialog}
        >
          <RefundForm
            onCancel={this.handleCloseDialog}
            refundableOrder={refundableOrder}
            deliveryOptions={deliveryOptions}
          />
        </FormDialog>
      </div>
    );
  }
}

export default OrdersList;
