import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { DiscountModel, DiscountsCollection } from '@models/discount';

import FormDialog from '@components/shared/FormDialog';

import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import http from '@/utils/http';
import DiscountForm from './DiscountForm';


class DiscountsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDialogOpen: false,
      editingDiscount: null,
      discounts: props.discounts,
    };
  }

  handleEditDiscount = ({ target }) => {
    this.setState((prevState) => {
      const discount = prevState.discounts.findById(target.dataset.itemId);

      return {
        isFormDialogOpen: true,
        editingDiscount: discount,
      };
    });
  }

  handleCloseDialog = () => {
    this.setState({ isFormDialogOpen: false });
  };

  handleDiscountSuccessSubmit = (updatedDiscount) => {
    this.setState((prevState) => {
      const { discounts } = prevState;
      const index = _.findIndex(discounts.models, (discount) => discount.id === updatedDiscount.id);
      discounts.models[index] = updatedDiscount;

      return {
        isFormDialogOpen: false,
        editingDiscount: null,
        discounts,
      };
    });
  };

  approve = (discountId) => {
    const { refreshCart } = this.props;

    http
      .put(DiscountModel.routes.approvePath(discountId))
      .then((response) => {
        const updatedDiscount = new DiscountModel(response.data);

        this.setState((prevState) => {
          const { discounts } = prevState;
          const index = _.findIndex(discounts.models, (discount) => discount.id === updatedDiscount.id);
          discounts.models[index] = updatedDiscount;

          return {
            discounts,
          };
        });

        refreshCart(updatedDiscount.cart_id);
      })
      .catch((error) => {
        throw error;
      });
  }

  destroy = (discountId) => {
    const { refreshCart } = this.props;

    http
      .delete(DiscountModel.routes.deletePath(discountId))
      .then((response) => {
        this.setState((prevState) => {
          const { discounts } = prevState;
          const index = _.findIndex(discounts.models, (discount) => discount.id === discountId);
          discounts.models.splice(index, 1);

          return {
            discounts,
          };
        });

        refreshCart(response.data.cart_id);
      })
      .catch((error) => {
        throw error;
      });
  }

  render() {
    const { discounts, isFormDialogOpen, editingDiscount } = this.state;

    const {
      globalDiscountTemplates,
      discountTemplates,
      adminRole,
      refreshCart,
      discountableCarts,
    } = this.props;

    const columns = [
      {
        Header: '',
        Cell: ({ original }) => {
          const cartDiscountable = discountableCarts.find((cart) => cart.id === original.cart_id);
          if (cartDiscountable) {
            return (
              <>
                <button
                  className="btn btn-sm btn-outline-primary"
                  data-item-id={original.id}
                  onClick={this.handleEditDiscount}
                >
                  Edit
                </button>
                <button
                  className="btn btn-outline-danger btn-sm mx-1"
                  onClick={() => this.destroy(original.id)}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </button>
              </>
            );
          }
          return '';
        },
        sortable: false,
        resizable: false,
        width: 105,
      },
      {
        Header: 'Cart #',
        accessor: 'cart_id',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Drug',
        accessor: 'product_name',
      },
      {
        Header: 'Type',
        accessor: 'type_name',
      },
      {
        Header: 'Coupon',
        accessor: 'value',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Creating date',
        accessor: 'formattedCreatedAt',
      },
      {
        Header: 'By',
        accessor: 'admin_name',
      },
      {
        Header: 'Apvd by',
        Cell: ({ original }) => {
          if (adminRole === 'superadmin' && !original.approved) {
            return (
              <span className="inline-edit-buttons">
                <button
                  className="btn btn-outline-success btn-sm mx-1"
                  onClick={() => this.approve(original.id)}
                >
                  <CheckIcon fontSize="small" />
                </button>
              </span>
            );
          }
          if (original.approved_by_admin_id) {
            return original.approved_by_admin_name;
          }
          return '';
        },
      },
    ];

    return (
      <div>
        <FormDialog
          title="Edit discount"
          isOpen={isFormDialogOpen}
          onClose={this.handleCloseDialog}
          customClass="dialog__wide"
        >
          <DiscountForm
            globalDiscountTemplates={globalDiscountTemplates}
            discountTemplates={discountTemplates}
            discount={editingDiscount}
            onCancel={this.handleCloseDialog}
            handleDiscountSuccessSubmit={this.handleDiscountSuccessSubmit}
            refreshCart={refreshCart}
            discountableCarts={discountableCarts}
          />
        </FormDialog>

        <ReactTable
          minRows={1}
          columns={columns}
          data={discounts.models}
          defaultPageSize={10}
          pageSizeOptions={[10, 30, 50]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

DiscountsList.propTypes = {
  discounts: PropTypes.instanceOf(DiscountsCollection).isRequired,
};

export default DiscountsList;
