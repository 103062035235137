import moment from 'moment';

import { BaseModel, BaseCollection } from './base';

class OrderModel extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  defaults() {
    return {
      id: null,
      total_price: 0.0,
      delivery_type: null,
      final_delivery_price: 0.0,
      state: 'draft',
      group: '',
      created_at: '',
      voided_by: '',
      voidable: false,
      refunded_value: null,
      refunded_by: '',
      refundable: false,
      terms_of_service: false,
      tracking_number: '',
      admin: {
        id: null,
        fullname: '',
      },
    };
  }

  get formattedCreatedAt() {
    return moment(this.created_at).format('L');
  }

  get isDraft() {
    return this.state === 'draft';
  }

  get deliveryPriceWithCurrency() {
    return `$${Number(this.delivery_price).toFixed(2)}`;
  }

  get finalDeliveryPriceWithCurrency() {
    return `$${Number(this.final_delivery_price).toFixed(2)}`;
  }

  get isPending() {
    return this.state === 'pending';
  }

  get isSubmitted() {
    return this.state === 'submitted';
  }

  get isAwaitingShipment() {
    return this.state === 'awaiting_shipment';
  }

  get isShipped() {
    return this.state === 'shipped';
  }

  get refundInfo() {
    if (!this.refunded_value) return null;

    return `- $${Number(this.refunded_value).toFixed(2)} by ${this.refunded_by}`;
  }

  get totalPriceWithCurrency() {
    return `$${Number(this.total_price).toFixed(2)}`;
  }

  static get routes() {
    return {
      ordersPath: '/orders',
      orderPath: (id) => `/orders/${id}`,
      orderCartPath: (orderId, id) => `/orders/${orderId}/carts/${id}`,
      orderAssignCreditCard: (id) => `/orders/${id}/assign_credit_card`,
      orderPaymentsPath: (id) => `/orders/${id}/payments`,
      withdrawOrderPaymentsPath: (id) => `/orders/${id}/payments/withdraw`,
      orderRefundsPath: (id) => `/orders/${id}/refunds`,
      applyDiscountOrderCartPath: (orderId, id) => `/orders/${orderId}/carts/${id}/apply_discount`,
      removeDiscountOrderCartPath: (orderId, id) => `/orders/${orderId}/carts/${id}/remove_discount`,
      orderAwaitingShipmentPath: () => '/orders/awaiting_shipment',
    };
  }
}

class OrdersCollection extends BaseCollection {
  // eslint-disable-next-line class-methods-use-this
  model() {
    return OrderModel;
  }

  findByGroup(group) {
    return this.models.filter((model) => model.group === group);
    ;
  }

  findByState(state) {
    return this.models.filter((model) => model.state === state);
  }
}

export { OrderModel, OrdersCollection };
